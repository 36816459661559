<template>
    
    <div class="card mb-2 p-2">
                <div class="row">
                    <div class="col text-center">
                        <h3 style=" padding-right: 40px;">Административная панель</h3>
                        <span class="p-0 m-0">
                            <router-link to="/" v-b-tooltip.hover title="Назад" class="btn btn-outline p-1">
                                <i class="fa fa-2x fa-arrow-left"></i>
                            </router-link>
                            <router-link to="/admin/users"  v-b-tooltip.hover title="Пользователи" class="btn btn-outline p-1">
                                <i class="fa fa-2x fa-users"></i>
                            </router-link>
                            <router-link to="/admin/mailing" v-if="$auth.user().isAdmin"  v-b-tooltip.hover title="Рассылка"  class="btn btn-outline p-1">
                                <i class="fa fa-2x fa-envelope"></i>
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="row pt-5"><div class="col">
            <router-view></router-view>
                    </div>
                </div>

    </div>
</template>
<script>
    export default {
        components: {
        },
        data() {
            return {
                test:null,
                errors:null
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>