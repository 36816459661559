<template>
  <div>
      <b-card>
          <h3>Email-рассылка</h3>
                <blockquote>
        Здесь вы можете рассылать e-mail сообщения.</blockquote>
          <b-row>
              <b-col cols="8">
    <b-form @submit="onSubmit">
              <b-form-radio-group @input="getRecipients()" class="my-2"
                id="radio-group-1"
                v-model="form.sendToAll"
                :options="[
                  { text: 'Выбрать получателей', value: '0' },
                  { text: 'Разслать всем', value: '1' }
                ]"
                name="radio-options"
              ></b-form-radio-group>
   
        Получателей: {{count}} <b-btn v-b-modal.targets  v-if="targets" size="sm" class="mx-2 p-1">Посмотреть список</b-btn>
        
        <div   style="width:800px">
        <center >
                  <b-form-group id="themeInputGroup"
                    label="Тема сообщения:" 
                    label-for="themeInput">
        <b-form-input id="themeInput"
                      type="text"
                      v-model="form.theme"
                      required
                      placeholder="Напишите тему">
        </b-form-input>
      </b-form-group>
            <h5>Сообщение:</h5>
        <vue-editor v-model="form.mail"></vue-editor></center>
        </div>
      <b-button @click.prevent="onSubmit()" variant="success" class='m-1'>Разослать</b-button>
      <b-button @click.prevent="save()" variant="primary" class='m-1'>Сохранить черновик</b-button>
    </b-form>
              </b-col>
              <b-col>
                  <b-card>'-461223010',-327073259, -469580294
                              <b-form-input id="themeInput"
                      type="text"
                      v-model="telegram.chat_id"
                      required
                      placeholder="Ид чата">
                                  </b-form-input>
                                                    <b-form-input id="themeInput"
                      type="text"
                      v-model="telegram.message"
                      required
                      placeholder="Сообщение">
                                  </b-form-input>
                      <b-button @click.prevent="telegramSend()" variant="success" class='m-1'>Отправить</b-button>
                  </b-card>
                  <b-card class="my-2">
                      <h3>Черновики</h3>
                  </b-card>
                  <b-card class="my-2">
                      <h3>История</h3>
                  </b-card>
              </b-col>
          </b-row>
      </b-card>
      <b-modal id="targets" hide-footer>
          <ul>
              <li v-for="user in targets" :key="user.user_id">{{user.last_name}} {{user.name}} {{user.second_name}} ({{user.email}})</li>
          </ul>
      </b-modal>
  </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  data () { 
      return {
      form: {
        mail: '',
        theme: null,
        sendToAll:0,
      },
      targets: [],
      count:null,
      show: true,
      telegram: {
          chat_id:null,
          message:null
      }
    }
  },

  methods: {
    onSubmit () {
        this.$http.post('admin/mailing/send', this.form)
        .then(() => {
          // JSON responses are automatically parsed.
          alert('Отправлено')
        })
        .catch(e => {
          alert('Возникла ошибка')
          console.log(e)
        })
    },
    telegramSend () {
        this.$http.post('admin/mailing/telegram-send?chat_id='+this.telegram.chat_id +'&message='+this.telegram.message)
        .then(() => {
          // JSON responses are automatically parsed.
          alert('Отправлено')
        })
        .catch(e => {
          alert('Возникла ошибка')
          console.log(e)
        })
    },
      getRecipients(){
            this.$http.post('admin/mailing/get-recipients', {
                sendToAll: this.form.sendToAll
            })
            .then(({ data }) => {
                this.count=data.count
        this.targets=data.result
            })
            .catch(function (e) {
                this.errors.push(e)
            })
      }
  },
  created () {
    this.fetchData()
    this.getRecipients()
  }
}
</script>
