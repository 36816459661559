<template>
    <div id="users_list">
        <b-card>
            <v-server-table ref="serverTable" :columns="columns" :options="options">  

                <div slot="beforeTable" v-if="selected && selected.length>0" class='m-1'>
                    Выбрано: {{selected.length}}. <b-btn size='sm'>Отправить сообщение</b-btn>
                </div>
                <div slot="filter__selected" class="text-center" >
                    <b-form-checkbox @change="selectAll($event)" size="lg" />
                </div>
                <span slot="selected" slot-scope="props" class="text-center">
                    <b-form-checkbox
                        v-model="selected"
                        :value="props.row.user_id"
                        />
                </span>
                <div slot="child_row" slot-scope="props">
                    <crmTasks :moderators="managers" :tasks="props.row.crmTasks" :user_id="props.row.user_id"></crmTasks>
                </div>
                <span slot="name" slot-scope="props" class="text-center">


                    <h4><b-badge variant='success' size='xl' v-if='props.row.partner=="mst"'>МСТ</b-badge></h4>

                    {{props.row.last_name}} <br>{{props.row.name}} <br>{{props.row.second_name}} 

                </span>
                <span slot="email" slot-scope="props" class="text-center">
                    Email: {{props.row.user.email}}<br>
                    Телефон: {{props.row.user.phone}} <br>
                </span>                        

                <span slot="actions" slot-scope="props">
                    <center>
                        <a href="javascript: void(0)" @click="userEdit(props.row)" class="m-1"><i class="fa fa-2x fa-cog"></i></a>
                        <a @click="login(props.row.user_id)" href="#" class="m-1"><i class="fa fa-2x fa-user-secret"></i></a>
                    </center>
                </span>
                <span slot="confirmed" slot-scope="props">
                    <center>
                        <h6>
                            <span v-if="props.row.user.confirmed_at"><b-badge variant="success">Подтвержден<br> {{ props.row.user.confirmed_at | moment("d/MM/YYYY, H:mm:ss")}}</b-badge></span>
                            <span v-else><b-badge variant="danger"><i>не подтвержден</i><br><b-btn size="sm" class="m-1" @click="confirm(props.row.user_id,props.index)">Подтвердить</b-btn></b-badge> </span>

                            <b-badge variant="danger" class="m-1" v-if="props.row.user.blocked_at"><i>Заблокирован</i>
                                <a href="javascript: void(0)" class="m-1" @click="lock(props.row.user_id,props.index)">      
                                    <i class="fa fa-unlock-alt fa-2x text-success"></i>
                                </a></b-badge>
                        </h6>
                        <a v-if="!props.row.user.blocked_at" href="javascript: void(0)" class="m-1" @click="lock(props.row.user_id,props.index)">      
                            <i class="fa fa-lock fa-2x text-danger"></i>
                        </a>
                        <a href="javascript: void(0)" v-on:click.prevent="$refs.remove_modal.index=props.index; $refs.remove_modal.user_id = props.row.user_id" v-b-modal.remove_modal class="m-1"><i class="fa fa-2x fa-trash"></i></a>
                    </center>
                </span>

            </v-server-table>
        </b-card>
        <b-modal id="settings" ref="settings" title="Настройки пользователя" size="xl" hide-footer>
            <settings v-if="currentUser && currentUser.user_id" @update="currentUser=$event" :user_id="currentUser.user_id"></settings>
        </b-modal>
        <b-modal ref="remove_modal" hide-footer id="remove_modal" title="Удаление" >
            <div class="d-block text-center">
                <h3>Вы действительно хотите удалить этого пользователя? Это действие необратимо.</h3>
            </div>
            <b-btn class="mt-3 btn-outline" variant="danger" block @click="remove($refs.remove_modal.user_id, $refs.remove_modal.index); $refs.remove_modal.hide()">Удалить </b-btn>
        </b-modal>
    </div>



</template>
<style>
    .VueTables__child-row-toggler {
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: block;
        margin: auto;
        text-align: center;
    }

    .VueTables__child-row-toggler--closed::before {
        content: "+";
    }

    .VueTables__child-row-toggler--open::before {
        content: "-";
    }

</style>
<script>
    import settings from '../user/settings'
    export default {
        name: 'users_list',
        components: {
            settings
        },

        data() {
            return {
                selected: [],
                currentUser: null,
                filter_activity_area: null,
                filter_activity_direction: null,
                columns: ['user_id', 'name', 'email', 'confirmed', 'actions'],
                options: {
                    uniqueKey: 'user_id',
                    headings: {selected: '#', user_id: 'ИД', 'name': 'ФИО', 'email': 'Контакты', actions: 'Действия', confirmed: 'Доступ'},
                    skin: 'table table-sm table-striped table-bordered table-hover',
                    filterByColumn: true,
                    filterable: ['user_id', 'name', 'email'],
                    sortable: ['user_id', 'name'],
                    sortIcon: {base: 'fa', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'},
                    columnsDropdown: false,
                    perPage: 25,
                    debounce: 1000,
                    perPageValues: [10, 25, 50, 100],
                    responseAdapter: function (resp) {
                        resp = this.getResponseData(resp)
                        return {data: resp.data.profile, count: resp.data.count}
                    },
                    requestFunction: function (data) {
                        return this.$http.post('admin/users/get?page=' + data.page + '&per_page=' + data.limit, {
                            query: data.query,
                            sort: data.orderBy,
                            ascending: data.ascending
                        }).catch(function (e) {
                            this.dispatch('error', e)
                        }.bind(this))
                    },
                    texts: {
                        count: "Показано с {from} по {to} из {count} записей|{count} записей|Одна запись",
                        first: 'Начало',
                        last: 'Конец',
                        filter: "Фильтр",
                        filterPlaceholder: "Поиск",
                        limit: "Записей:",
                        page: "Страница:",
                        noResults: "Ничего не найдено",
                        filterBy: "{column}",
                        loading: 'Загрузка...',
                        defaultOption: 'Выбрано {column}',
                        columns: 'Колонки'
                    }
                }
            }
        },
        watch: {
            // при изменениях маршрута запрашиваем данные снова
            '$route': 'getInfo',
        },
        methods: {
            selectAll(e) {
                if (e) {
                    this.selected = this.$refs.serverTable.$refs.table.data.map(el => el.user_id)
                } else
                {
                    this.selected = []
                }
            },
            getIndex(index)
            {
                return index - this.$refs.serverTable.$refs.table.limit * (this.$refs.serverTable.$refs.table.page - 1) - 1
            },
            remove(user_id, index) {
                index = this.getIndex(index)
                this.$http.get('admin/users/remove', {
                    params: {
                        id: user_id,
                    }
                })
                        .then(() => {
                            // JSON responses are automatically parsed.
                            this.$refs.serverTable.$refs.table.data.splice(index, 1);
                        })
                        .catch(() => {
                            alert('Возникла ошибка')
                        })
            },
            lock(user_id, index) {
                index = this.getIndex(index)
                this.$http.get('admin/users/lock', {
                    params: {
                        id: user_id,
                    }
                })
                        .then(response => {
                            // JSON responses are automatically parsed.
                            this.$set(this.$refs.serverTable.$refs.table.data[index].user, 'blocked_at', response.data)
                        })
                        .catch(() => {
                            alert('Возникла ошибка')
                        })
            },
            confirm(user_id, index) {
                index = this.getIndex(index)
                this.$http.get('admin/users/confirm', {
                    params: {
                        id: user_id,
                    }
                })
                        .then(response => {
                            // JSON responses are automatically parsed.
                            this.$set(this.$refs.serverTable.$refs.table.data[index].user, 'confirmed_at', response.data)
                        })
                        .catch(() => {
                            alert('Возникла ошибка')
                        })
            },
            userEdit(user) {
                this.currentUser = user
                this.$refs.settings.show()
            },
            login(user_id) {
                this.$auth.login({
                    url: 'auth/login?user_id=' + user_id,
                    redirect: {name: 'Index'},
                })
                        .then(() => {
                            console.log('success ' + this.context)
                        }).catch(e => {
                    this.error = e.response.data
                })
            },
        },
        created() {
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .VueTables__limit .form-group{
        width: auto !important;
    }
    .VueTables__search-field {
        display: flex;
    }
    .VueTables__limit-field {
        display: flex;
    }
</style>
